// Zone.js
import 'zone.js/dist/zone';

if (APP_MODE == 'development') {
    // Development and test
    Error['stackTraceLimit'] = Infinity;
    require('zone.js/dist/long-stack-trace-zone');
}

// Styles
import './vendor.scss';

// tw Bootstrap
import 'popper.js';
import 'bootstrap';

// Slick Carousel plugin
import 'slick-carousel';

// Bootstrap-datepicker
import 'bootstrap-datepicker';
import 'bootstrap-datepicker/js/locales/bootstrap-datepicker.ru.js';

// Moment TZ
import 'moment-timezone';
